import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/keycloak.auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {
  public event$;
  public adminRoot = environment.adminRoot;
  public appPath = '';
  public msg = `Une erreur s'est produite, veuillez rééssayer ultérieurement.`;
  public logout = false;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.appPath = localStorage.getItem('appPath');
  }

  ngOnInit(): void {
    this.msg = this.route.snapshot.queryParams?.msg || this.msg;
    this.logout = this.route.snapshot.queryParams?.logout || this.logout;
    this.router.navigate([this.appPath + '/error']);
    document.body.classList.add('background');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('background');
    this.event$.unsubscribe();
  }

  goBackOrLogout(): void {
    if (this.logout) {
      this.authService.logout();
    } else {
      window.location.href = window.location.origin + '/' + this.appPath;
    }
  }
}
