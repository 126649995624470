import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit, OnDestroy {
    public event$;
    public adminRoot = environment.adminRoot;

    constructor(private route: ActivatedRoute, private router: Router) {
        window.location.href = 'https://www.mutuelleducommerce.nc/';
        this.event$ = this.router.events.subscribe(
            (event) => {
                if (event instanceof NavigationEnd) {
                    if (event.url.includes('adherent')) {
                        this.router.navigate(['adherent/' + this.adminRoot]);
                    } else if (event.url.includes('employeur') || event.url.includes('entreprise')) {
                        this.router.navigate(['employeur/' + this.adminRoot]);
                    } else {
                        window.location.href = 'https://www.mutuelleducommerce.nc/';
                    }
                }
            }
        );
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.event$.unsubscribe();
    }

}
