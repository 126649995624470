import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectComponent } from '../views/redirect/redirect.component';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import { LogoComponent } from '../views/user/components/logo/logo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { QuillModule } from 'ngx-quill';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HotkeyModule } from 'angular2-hotkeys';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxMaskModule } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BootstrapModule } from '../components/bootstrap/bootstrap.module';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [ErrorComponent, RedirectComponent, UnauthorizedComponent, LogoComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    PaginationModule.forRoot(),
    CollapseModule,
    HotkeyModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    BsDropdownModule.forRoot(),
    BootstrapModule,
    ArchwizardModule,
    NgxDatatableModule,
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    RedirectComponent,
    ErrorComponent,
    UnauthorizedComponent,
    TranslateModule,
    CommonModule,
    LogoComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule,
    ContextMenuModule,
    PaginationModule,
    CollapseModule,
    HotkeyModule,
    ModalModule,
    NgxMaskModule,
    BsDropdownModule,
    BootstrapModule,
    ArchwizardModule,
    NgxDatatableModule,
  ],
})
export class SharedModule {}
