<div class="modal-header">
    <h5 class="modal-title pull-left"> Acceptation des CGU </h5>
</div>

<div class="modal-body py-3">
    <p>En cochant cette case, vous certifiez avoir lu et accepté sans réserve les nouvelles conditions générales d'utilisation</p>
    <p *ngIf="!displayCgu">
        <a class="c-pointer" (click)="displayCgu = !displayCgu">Lire les conditions générales d'utilisation</a>
    </p>
    <div id="cgu-container" style="height: 30vh; overflow-y: scroll;" *ngIf="displayCgu">
        <app-cgu></app-cgu>
    </div>
    <div class="d-flex align-items-center mt-2" [ngClass]="displayCgu ? 'justify-content-center' : 'justify-content-start'">
        <input type="checkbox" aria-label="Checkbox for following text input" (change)="onChange($event.target.checked)">
        <span class="ml-2"> J'accepte </span>
    </div>
    <div class="d-flex justify-content-end align-items-center mt-2">
        <button class="btn btn-secondary" (click)="onClose()">Fermer</button>
    </div>
</div>

<simple-notifications></simple-notifications>
