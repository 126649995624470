import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() logoWidth = '';
  @Input() logoHeight = '';
  @Input() adminRoot = '';
  constructor(private router: Router) {

  }
}
