import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';

import { MockHttpInterceptor } from './data/mock_http_interceptor';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AuthService } from './shared/keycloak.auth.service';
import { environment } from 'src/environments/environment';
import { KeycloakConfig } from 'keycloak-js';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
    expiryDays: 182.5,
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#004071',
    },
  },
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': `{{messagelink}} {{allow}} {{deny}}`
  },
  theme: 'classic',
  elements: {
    messagelink: `
      <span id="cookieconsent:desc" class="cc-message">
        Le site
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{mdchref}}" target="_blank"> {{mdclink}} </a>
        {{message}}
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank"> {{link}} </a>
      </span>
    `,
    header: `<span class="cc-header"> {{header}} </span>`,
    message: `<span id="cookieconsent:desc" class="cc-message"> {{message}} </span>`,
    allow: `<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow nowrap"> {{allow}} </a>`,
    deny: `<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny nowrap"> {{deny}} </a>`,
    link: `<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank"> {{link}} </a>`,
  },
  content: {
    allow: `J'accepte`,
    deny: `Je refuse`,
    href: window.location.href.includes('employeur')
      ? `/employeur/app/politique-relative-aux-cookies`
      : `/adherent/app/politique-relative-aux-cookies`,
    link: `Consultez la politique de gestion des cookies`,
    message: `utilise des cookies pour dresser des statistiques de fréquentation et permettre un partage de contenus sur les réseaux sociaux.`,
    mdchref: `https://www.mutuelleducommerce.nc/`,
    mdclink: `Mutuelle du Commerce Nouvelle-Calédonie | Mutuelle du Commerce Nouvelle-Calédonie`,
    policy: `Cookie Policy`,
  }
};


interface KCConfigFile {
  adherent: KeycloakConfig;
  employeur: KeycloakConfig;
}


function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  return async (): Promise<boolean> => {
    const configFile = await fetch('assets/json/keycloak.json');
    const configData: KCConfigFile = await configFile.json();
    const config: KeycloakConfig = window.location.href.includes('employeur') ? configData.employeur : configData.adherent;
    return await keycloak.init({
      config,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      bearerPrefix: 'JWT',
    });
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    KeycloakAngularModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    AuthService
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SimpleNotificationsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
