export const ENDPOINT = {
  infocguadh: {
    path: 'infocguadh',
    desc: 'Récupération informations CGU de l’adhérent ',
    ws: 'WS006',
  },
  majcguadh: {
    path: 'majcguadh',
    desc: 'Modification des CGU, mise à jour date acceptation',
    ws: 'WS007',
  },
  infopersoadherent: {
    path: 'infopersoadherent',
    desc: 'Récupération informations personnelles d’un adhérent',
    ws: 'WS020',
  },
  recupadresseadh: {
    path: 'recupadresseadh',
    desc: 'Récupération de l’adresse de correspondance détaillée d’un adhérent',
    ws: 'WS034',
  },
  majtelephoneadh: {
    path: 'majtelephoneadh',
    desc: 'Modification des n° de téléphone d’un adhérent',
    ws: 'WS021',
  },
  majadresseadh: {
    path: 'majadresseadh',
    desc: 'Modification de l’adresse de correspondance d’un adhérent',
    ws: 'WS022',
  },
  ctremailprinc: {
    path: 'ctremailprinc',
    desc: 'Contrôle validité et unicité de l’adresse courriel principal',
    ws: 'WS035',
  },
  majmailprinc: {
    path: 'majmailprinc',
    desc: 'Modification de l’adresse courriel principal (adhérent)',
    ws: 'WS036',
  },
  majcourrielcompadh: {
    path: 'majcourrielcompadh',
    desc: 'Modification de l’adresse courriel complémentaire d’un adhérent',
    ws: 'WS023',
  },
  majacceptcommadh: {
    path: 'majacceptcommadh',
    desc: 'Modification acceptation commerciale',
    ws: 'WS024',
  },
  listebenefadh: {
    path: 'listebenefadh',
    desc: 'liste des bénéficiaires de l’adhésion adhérent',
    ws: 'WS025',
  },
  listecpadr: {
    path: 'listecpadr',
    desc: 'Liste des codes postaux d’adresses physiques',
    ws: 'WS032',
  },
  listecpbp: {
    path: 'listecpbp',
    desc: 'Liste des codes postaux des BP',
    ws: 'WS033',
  },
  attestfamille: {
    path: 'attestfamille',
    desc: 'Téléchargement d’une attestation familiale de l’adhérent',
    ws: 'WS050',
  },
  attestbenef: {
    path: 'attestbenef',
    desc: 'Téléchargement d’une attestation d’adhésion d’un bénéficiaire',
    ws: 'WS051',
  },
  attestfiscale: {
    path: 'attestfiscale',
    desc: 'Téléchargement d’une attestation de déductibilité fiscale',
    ws: 'WS052',
  },
  appelrecapadh: {
    path: 'appelrecapadh',
    desc: 'Téléchargement de l’appel de cotisation récapitulatif',
    ws: 'WS053',
  },
  appeldetailadh: {
    path: 'appeldetailadh',
    desc: 'Téléchargement de l’appel de cotisation détaillé',
    ws: 'WS054',
  },
  situationcompteadh: {
    path: 'situationcompteadh',
    desc: 'Téléchargement de la situation de compte',
    ws: 'WS055',
  },
  infopaiecotisadh: {
    path: 'infopaiecotisadh',
    desc: 'Informations de paiement de cotisations d’un adhérent',
    ws: 'WS026',
  },
  histomvtcotisadh: {
    path: 'histomvtcotisadh',
    desc: 'Récupération de l’historique des mouvements de cotisations',
    ws: 'WS027',
  },
  infopaiepresta: {
    path: 'infopaiepresta',
    desc: 'Informations bancaire de remboursement de prestations d’un adhérent',
    ws: 'WS028',
  },
  histomvtpresta: {
    path: 'histomvtpresta',
    desc: 'Historique remboursements de prestations',
    ws: 'WS029',
  },
  detailfacture: {
    path: 'detailfacture',
    desc: 'Détails des factures d’un règlement de prestation',
    ws: 'WS030',
  },
  listeacte: {
    path: 'listeacte',
    desc: 'Détail d’une facture de prestation',
    ws: 'WS031',
  },
  decompte: {
    path: 'decompte',
    desc: 'Téléchargement décompte de prestation',
    ws: 'WS056',
  },
  majcguemp: {
    path: 'majcguemp',
    desc: 'Modification date acceptation CGU',
    ws: 'WS107',
  },
  infopersoemployeur: {
    path: 'infopersoemployeur',
    desc: 'Récupération informations personnelles d’un employeur',
    ws: 'WS120',
  },
  majtelephoneemp: {
    path: 'majtelephoneemp',
    desc: 'Modification des n° de téléphone d’un employeur',
    ws: 'WS121',
  },
  recupadresseemp: {
    path: 'recupadresseemp',
    desc: 'Informations adresse de correspondance détaillée d’un employeur',
    ws: 'WS128',
  },
  majadresseemp: {
    path: 'majadresseemp',
    desc: 'Modification de l’adresse de correspondance d’un employeur',
    ws: 'WS122',
  },
  majcourrielcompemp: {
    path: 'majcourrielcompemp',
    desc: 'Modification de l’adresse courriel complémentaire d’un employeur',
    ws: 'WS123',
  },
  majacceptcommemp: {
    path: 'majacceptcommemp',
    desc: 'Modification acceptation commerciale',
    ws: 'WS124',
  },
  listebenefemp: {
    path: 'listebenefemp',
    desc: 'Liste des bénéficiaires de l’adhésion employeur',
    ws: 'WS125',
  },
  infopaiecotisemp: {
    path: 'infopaiecotisemp',
    desc: 'Informations de paiement de cotisations d’un employeur',
    ws: 'WS126',
  },
  histomvtcotisemp: {
    path: 'histomvtcotisemp',
    desc: 'Récupération de l’historique des mouvements de cotisations',
    ws: 'WS127',
  },
  majmailprincemp: {
    path: 'majmailprincemp',
    desc: 'Modification de l’adresse courriel principal (employeur)',
    ws: 'WS136',
  },
  appelrecapemp: {
    path: 'appelrecapemp',
    desc: 'Téléchargement de l’appel de cotisation récapitulatif',
    ws: 'WS153',
  },
  appeldetailemp: {
    path: 'appeldetailemp',
    desc: 'Téléchargement de l’appel de cotisation détaillé',
    ws: 'WS154',
  },
  situationcompteemp: {
    path: 'situationcompteemp',
    desc: 'Téléchargement de la situation de compte',
    ws: 'WS155',
  },
  fichiercotisemp: {
    path: 'fichiercotisemp',
    desc: 'Téléchargement du fichier .csv des cotisations d’un employeur',
    ws: 'WS156',
  },
  creationdelegation: {
    path: 'creationdelegation',
    desc: 'Création d’une délégation',
    ws: 'WS158',
  },
  suppressiondelegation: {
    path: 'suppressiondelegation',
    desc: 'Suppression d’une délégation',
    ws: 'WS159',
  },
  modificationdelegation: {
    path: 'modificationdelegation',
    desc: 'Modification d’une délégation',
    ws: 'WS160',
  }
};
