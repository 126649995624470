<div class="bg-light col-11 shadow border p-5 mx-auto mb-2">

    <div class="row">
        <div class="col-12 px-4">
            <h2 class=""> ARTICLE 1. APPLICATION DES CONDITIONS GENERALES – OPPOSABILITE </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                La MDC fournit sur son Site <a href="https://www.mutuelleducommerce.nc" target="_blank">Mutuelle du Commerce Nouvelle-Calédonie | Mutuelle du Commerce Nouvelle-Calédonie</a> des services au bénéfice de ses adhérents.
            </p>
            <p>
                Le Site <a href="https://www.mutuelleducommerce.nc" target="_blank">Mutuelle du Commerce Nouvelle-Calédonie | Mutuelle du Commerce Nouvelle-Calédonie</a> permet d’accéder à son espace personnel. Les présentes conditions générales d’utilisation (CGU) ont pour objet de préciser les conditions d’accès et d’utilisation dans laquelle la MDC met à disposition de l’utilisateur ledit service.
            </p>
            <p>
                L’accès au service et son utilisation impliquent l’acceptation de plein droit des présentes CGU. Dans le cas où l'Utilisateur ne souhaite pas accepter tout ou partie des présentes conditions générales, il lui est demandé de renoncer à tout usage du service.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 2 – DÉFINITIONS </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p class="mb-1">
                Chacun des termes mentionnés ci-dessous, qu’il commence par une majuscule ou une minuscule ou qu’il soit utilisé au singulier ou au pluriel, aura dans les présentes conditions générales d’utilisation du Téléservice (ci-après « CGU ») la signification suivante :
            </p>
            <ul>
                <li> « Espace personnel » désigne l’espace dont l’accès est réservé à l’Utilisateur sur le Site, sur la base d’un identifiant et d’un mot de passe confidentiel lui permettant d’accéder à certains Services. </li>
                <li> « Services » désigne l’ensemble des services en ligne fournis par la MDC via son Site Internet, notamment la mise à disposition d'un accès à un compte personnel. </li>
                <li> « Site Internet » désigne le Site <a href="https://www.mutuelleducommerce.nc" target="_blank">Mutuelle du Commerce Nouvelle-Calédonie | Mutuelle du Commerce Nouvelle-Calédonie</a> édité et exploité par la MDC et permettant d’accéder au Service. </li>
                <li> « Utilisateur » désigne toute personne physique ou morale qui visite une ou plusieurs pages du Site Internet et/ou bénéficie du Service. </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 3 – SERVICES EN LIGNE PROPOSÉS </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Les services présentés ci-dessous ne sont pas listés de manière exhaustive. Ils pourront être modifiés, supprimés ou complétés sans que cela engendre nécessairement une modification des présentes CGU.
            </p>
            <h6> Service « Espace adhérent » </h6>
            <p class="mb-1">
                Ce service permet à l’adhérent, Utilisateur personne physique, de :
            </p>
            <ul>
                <li> Consulter les actualités de la MDC </li>
                <li> Consulter son/ses contrat(s) d’adhésion, ainsi que ses droits et ceux de ces bénéficiaires </li>
                <li> Consulter ses modalités de paiement </li>
                <li> Consulter et télécharger ses appels de cotisation (et/ou ses détails au mois) </li>
                <li> Consulter et télécharger la situation de son compte personnel </li>
                <li> Télécharger son attestation de déductibilité fiscale en cas de besoin </li>
                <li> Consulter ses modalités de remboursement </li>
                <li> Consulter ses remboursements et télécharger ses décomptes mensuels </li>
                <li> Modifier le mot de passe, l'adresse de correspondance, le numéro de téléphone renseigné et l’adresse électronique </li>
                <li> Télécharger les attestations d'adhésion à la garantie complémentaire santé </li>
            </ul>
            <h6> Service « Espace Employeur / Entreprise » </h6>
            <p class="mb-1">
                Cet espace est réservé aux personnes morales souscriptrices d’un contrat de garantie complémentaire frais de santé auprès de la MDC au bénéfice de leurs salariés ou membres. <br> Il permet de :
            </p>
            <ul>
                <li> Consulter certaines informations relatives à la personne morale souscriptrice </li>
                <li> Modifier le mot de passe, l'adresse de correspondance, le numéro de téléphone renseigné et l’adresse de courriel principale et complémentaire </li>
                <li> Consulter certaines informations concernant la garantie complémentaire santé souscrite </li>
                <li> Consulter les informations d'adhésion des salariés (et leurs ayants droit) de la personne morale souscriptrice du contrat complémentaire santé </li>
                <li> Faire une demande de radiation d'un bénéficiaire </li>
                <li> Consulter les modalités de paiement renseignées </li>
                <li> Consulter et télécharger les informations concernant les cotisations appelées dans le cadre du contrat complémentaire santé souscrit, le fichier CSV des cotisations, les cotisations versées, ainsi que la situation de compte </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 4 – EXCLUSION DES SERVICES AUX PRÉSENTES CONDITIONS </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Les présentes CGU ne s’appliquent pas au produit GARANTIMMO proposé par la MDC. Les conditions contractuelles dudit produit sont présentes sur le site <a href="https://mdc.securimut.fr" target="_blank">Votre assurance de prêt aux meilleurs prix et garanties avec la mdc (securimut.fr)</a>
            </p>
            <p>
                De même, pour la solution d’authentification <a href="https://connect.gouv.nc/#bienvenue" target="_blank">NC Connect</a>, la création et l’utilisation d’un compte sur NC Connect sont régies par les conditions générales d’utilisation de ce service, disponibles à cette <a href="https://connect.gouv.nc/cgus" target="_blank">adresse</a>.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 5 - MODALITÉS CONDITIONS D'ACCÈS AUX SERVICES </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h4> 5.1 - Création du compte et mot de passe </h4>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                L’utilisation du téléservice « Votre espace » de la MDC requiert une adhésion préalable à un de ses produits de garanties complémentaires.
            </p>
            <p class="mb-1">
                L’inscription à ce téléservice se fait par deux moyens à ce jour :
            </p>
            <ul>
                <li> dès la fourniture des éléments du contrat de garantie complémentaire, l’Usager reçoit un code d’activation par courriel et/ou courrier, à activer pour finaliser la procédure d’inscription au téléservice, permettant l’ouverture d’un compte personnel en ligne </li>
                <li> Ou bien directement en ligne, avec le service <a href="https://connect.gouv.nc/#bienvenue" target="_blank">NC Connect</a>. Lors de sa première connexion au téléservice, le Client est invité à créer son compte sur le portail NC Connect. Il fournit alors ses prénom et nom, date de naissance, son adresse électronique et crée un mot de passe (authentifiant). L’adresse électronique saisit peut être partagée avec les autres services publics en ligne auxquels vous avez consenti. Cette adresse est utilisée pour authentifier plus rapidement et simplement le Client sur son espace personnel </li>
            </ul>
            <p>
                L’Utilisateur est responsable de leur conservation, de la confidentialité et de l’utilisation faite. Il est également responsable des conséquences d’une divulgation de son identifiant et de son mot de passe, même involontaire, à toute personne. En ce sens, la responsabilité de la MDC ne pourra être engagé quant aux conséquences qui résulteraient d’un usage frauduleux ou abusif de ces derniers.
            </p>
            <p>
                En cas de perte ou d’oubli, le mot de passe peut être réinitialisé depuis la page de connexion à l’espace personnel.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h4> 5.2 - Coût d'accès </h4>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Les services proposés par la MDC sont accessibles à tout Utilisateur disposant d’un accès à Internet. Tous les coûts afférents à leur accès, que ce soient les frais matériels, logiciels ou d’accès à Internet sont exclusivement à la charge de l’Utilisateur.
                <br>
                Il est seul responsable du bon fonctionnement de son équipement informatique ainsi que son accès à Internet.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h4> 5.3 - Utilisation des services par l'Utilisateur </h4>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                L’Utilisateur s’engage à fournir des informations d’identification exactes, complètes et à jour (notamment son prénom, nom, adresse postale, adresse de messagerie…). Ces moyens de contact pourront être utilisés par la Mutuelle du Commerce pour communiquer des informations relatives à la garantie complémentaire frais de santé souscris. <br> L’Utilisateur s’engage à informer la Mutuelle du Commerce dans les plus brefs délais de toute modification de ces informations.
            </p>
            <p>
                L’Utilisateur s’engage à utiliser le Service conformément à l’usage pour lequel il a été défini et tel qu’il ressort des présentes conditions. Tout usage du Service contraire, constitue un détournement d’usage qui pourra donner lieu automatiquement à la fin d’accès aux services, sans préjudice de toute action judiciaire susceptible d’être intentée par la Mutuelle du Commerce et de tous dommages et intérêts qui pourraient être réclamés à l’Utilisateur.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h4> 5.4 - Acceptation des CGU </h4>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                L’utilisation du Service requiert dans tous les cas une acceptation préalable des présentes CGU.
            </p>
            <p>
                Ainsi toute personne souhaitant utiliser le Service doit prendre connaissance des présentes CGU et les accepter pour pouvoir créer son compte personnel lui permettant d’accéder aux différentes fonctionnalités dudit Service.
            </p>
            <p>
                En cochant la case d’acceptation des CGU proposée sur la page d’inscription au Service, chaque Client s’engage à respecter les règles qu’elles organisent.
            </p>
            <p>
                A défaut, il n’est pas possible de créer un compte personnel permettant d’utiliser le Service.
            </p>
            <p>
                Une fois les CGU acceptées, le Client peut revenir à tout moment sur son choix. <!-- à préciser, par exemple au moyen d’un onglet ad hoc sur son compte personnel -->
            </p>
            <p>
                Par ailleurs en cas de modification de tout ou partie des CGU par la MDC, le Client qui les a auparavant acceptées est invité à accepter les CGU nouvelles pour pouvoir continuer à utiliser le Service. <br>
                L’Adhérent qui ne renouvelle pas son acceptation dans un tel cas de figure n’a plus accès à son compte personnel. Il peut toutefois demander à récupérer ses informations et documents en suivant la procédure explicitée plus avant à l’article consacré à l’exercice des droits
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 6 – ENGAGEMENTS ET RESPONSABILITÉ DU CLIENT </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p class="mb-1">
                En acceptant les présentes CGU, le Client s’engage à :
            </p>
            <ul>
                <li>
                    Protéger ses éléments d’authentification (identifiant et mot de passe), notamment en observant les précautions élémentaires suivantes :
                    <ul>
                        <li> ne jamais demander à une autre personne de créer pour lui son mot de passe </li>
                        <li> choisir un mot de passe différent de ceux utilisés pour d’autres services sur internet </li>
                        <li> en changer en cas de suspicion de compromission </li>
                        <li> éviter de configurer son navigateur pour qu'il retienne ses éléments d’authentification </li>
                        <li> ne pas communiquer son mot de passe en clair sur internet </li>
                        <li> ne pas noter son mot de passe dans un fichier ou un document facilement accessible </li>
                        <li> naviguer avec un navigateur à jour </li>
                    </ul>
                </li>
                <li> Fournir des informations exactes </li>
                <li> Mettre à jour ses informations (et notamment ses coordonnées de contact) </li>
                <li> Ne pas utiliser d’identifiant ou d'adresse électronique qui pourrait porter atteinte aux droits des tiers (usurpation de nom patronymique, d’identifiant, d’autres droits) </li>
                <li> Alerter sans délai la MDC s’il découvre ou suspecte un incident de sécurité lors de l’utilisation du Service <!-- adresse de contact ISI --> </li>
            </ul>
            <p>
                L’Adhérent reconnait encore qu’il lui appartient de prendre toute mesure appropriée de façon à protéger ses propres outils informatiques et ses informations de la contamination d'éventuels virus ou programmes malfaisants.
            </p>
            <p>
                Enfin, le Client sait qu’en cas de fausse déclaration pour lui-même ou pour autrui, il s’expose, notamment, aux sanctions prévues à l’article 441-1 du Code Pénal prévoyant des peines pouvant aller jusqu’à trois ans d’emprisonnement et 5 369 924 XPF d’amende.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 7 – POLITIQUE DE PROTECTION DES DONNÉES PERSONNELLES </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                La MDC, dans le strict respect de la loi et des règlements en vigueur, recueille des informations à caractère personnel données par le Client, conformément aux dispositions relatives à la protection des données personnelles.
            </p>
            <p>
                L’utilisation des Services implique la mise en œuvre de traitements de données à caractère personnel, dont la <strong>responsabilité incombe</strong> à la MDC, située au 195 rue Gervolino, 98800 Nouméa, Nouvelle-Calédonie représentée par Monsieur Didier COURIER, en sa qualité de Directeur Général.
            </p>
            <p>
                Pour ces traitements et comme tous les autres mis en œuvre, la MDC s’attache à respecter le cadre juridique applicable, notamment de la loi n°78-17 du 6 janvier 1978 modifiée dite « loi Informatique et Libertés » et le Règlement (UE) 2016-679 du 27 avril 2016 dit « RGPD ».
            </p>
            <p>
                Pour toute précision au sujet des précautions générales prises par la MDC pour garantir le respect de la protection des données personnelles, le Client est invité à prendre connaissance de la <a routerLink="../politique-de-protection-des-donnees">Politique de protection des données</a> accessible en bas de page.
            </p>
            <p class="mb-1">
                Plus généralement, le Client peut se rapprocher du <strong>Délégué à la protection des données (ci-après « DPO »)</strong> de la MDC pour toute question relative au traitement de ses données par cette dernière :
            </p>
            <ul>
                <li> Soit par mail, à <a href="mailto:dpo@mutcom.nc">dpo@mutcom.nc</a> </li>
                <li> Ou bien par courrier postal, à : Mutuelle du Commerce et Divers, Délégué à la protection des données, 195 rue Gervolino, 98800 Nouméa. Il est recommandé d’envoyer ce courrier avec demande d’accusé de réception. </li>
            </ul>
            <p>
                Pour toute saisine du DPO, il importe d’indiquer clairement vos nom et prénom, et l’objet précis de votre saisine. Dans le cadre de l’instruction de celle-ci, un justificatif d’identité pourra vous être demandé.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 8 – DISPONIBILITÉ DU SERVICE </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Le Client pourra se connecter à tout moment au Site de la MDC, à savoir 24 heures sur 24, 7 jours sur 7.
            </p>
            <p>
                La MDC se réserve toutefois la faculté de faire évoluer, de modifier ou de suspendre, sans préavis, ses Services en ligne pour des raisons de maintenance ou pour tout autre motif jugé nécessaire. <br>
                L’indisponibilité desdits Services ne donne droit à aucune indemnité.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 9 – MISE À JOUR DES PRÉSENTES CGU </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Les présentes CGU peuvent être modifiées à tout moment en fonction des modifications apportées au Service, de l’évolution de la législation ou pour tout autre motif jugé nécessaire.
                <br>
                Dans un tel cas de figure, le Client est invité à accepter leur nouvelle version pour pouvoir continuer à utiliser le Service.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> ARTICLE 10 – LOI APPLICABLE ET ATTRIBUTION DE JURIDICTION </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Les présentes CGU sont soumises au droit français. Tout désaccord ou litige n'ayant pu trouver une issue transactionnelle sera porté devant les tribunaux compétents de Nouvelle-Calédonie.
            </p>
        </div>
    </div>

</div>
